import React from "react";
import Helmet from "react-helmet";
import gql from "graphql-tag";
import { Loader, Query } from "@deity/falcon-ecommerce-uikit";
import {
  SliderTop,
  HeadbandCarousel,
  StoreDescription,
  Headband,
  CardBrand,
  HomeBox,
  InstagramPosts,
  CarouselContentCards
} from "benlux-ecommerce-ui";
import { brand } from "../data/dataBrand";
import { CMSBlockQuery } from "../queries/CMSBlockQuery";
import { InstagramPostsQuery } from "../queries/InstagramPostsQuery";
import { Button, ButtonTypes } from "benlux-ecommerce-ui";
import { HomepageSlidesQuery } from "../queries/HomepageSlidesQuery";

const HOMEPAGE_PRODUCTS_QUERY = gql`
  query HomepageProducts($categoryId: String!, $limit: Int) {
    category(id: $categoryId) {
      name
      description
      urlPath
      products(pagination: { perPage: $limit, page: 1 }) {
        items {
          id
          name
          brandName
          description
          shortDescription
          promotionalBadge
          manufacturer
          price {
            regular
            special
          }
          configurableOptions {
            values {
              price {
                regular
                special
              }
            }
          }
          attributes {
            attributeCode
            value
          }
          gallery {
            full
            thumbnail
          }
          image
          thumbnail
          urlPath
        }
      }
    }
  }
`;

const Home = () => (
  <div>
    <Helmet>
      <meta name="twitter:card" content="summary" />
      <meta property="twitter:domain" content="benlux.fr" />
      <meta property="twitter:url" content={"https://www.benlux.fr"} />
      <meta
        name="twitter:title"
        content={
          "BENLUX ‣ Parfum, maquillage, parapharmacie, bijoux & montres ‣ BENLUX"
        }
      />
      <meta name="page-loaded" content="true" />
    </Helmet>
    <HomepageSlidesQuery>
      {({ homepageSlides, loading }) => {
        if (loading) {
          return <Loader />;
        }

        if (homepageSlides.length === 0) {
          return null;
        }

        return <SliderTop contents={homepageSlides} />;
      }}
    </HomepageSlidesQuery>
    <HomeBox>
      {/*<Query
        query={HOMEPAGE_PRODUCTS_QUERY}
        variables={{categoryId: '711', limit: 10}}
      >
        {({category}) => {
          return (
            <>
              <Headband
                title={category.name}
                description={category.description}
                showButton={true}
                urlPath={category.urlPath}
                textButton={'J\'en profite'}
              />
              <CMSBlockQuery variables={{identifier: 'header-soldes-contents'}}>
                {({ cmsBlock, loading }) => {
                  if (loading) {
                    return <Loader/>;
                  }

                  try {
                    const contents = JSON.parse(cmsBlock.content);

                    if (contents.length > 0) {
                      return (
                        <CarouselContentCards itemCarousel={contents}/>
                      );
                    }
                  } catch (e) {
                    console.error('An error occured while loading homepage slider', e);
                  }

                  return null;
                }}
              </CMSBlockQuery>
            </>
          );
        }}
      </Query>*/}
      {/*<div style={{
        maxWidth: '1200px',
        margin: '48px auto 24px',
        display: 'flex',
        gap: '36px',
        justifyContent: 'space-between',
        marginBottom: '24px'
      }}>
        <div style={{
          display: 'flex',
          border: '1px solid #CCC',
          borderRadius: '4px',
          alignItems: 'center',
          flex: 1
          }}>
          <div style={{
            height: '100%',
            minWidth: '45%',
            backgroundImage: 'url(https://www.sephora.fr/on/demandware.static/-/Library-Sites-SephoraV2/default/dwe84e9f18/SN-7790-FR-UNDERBANNER.jpg)',
            backgroundSize: 'cover'
          }}>
          </div>
          <div style={{padding: '24px'}}>
            <h3 style={{fontSize: '14px', marginTop: 0, marginBottom: '6px'}}>Les Bons Plans de la semaine</h3>
            <p style={{fontSize: '14px', marginTop: 0, marginBottom: '12px'}}>Chaque vendredi, découvrez une nouvelle sélection de produits avec des remises exceptionnelles !</p>
            <Button type={ButtonTypes.Tertiary} text="J'en profite"/>
          </div>
        </div>
        <div style={{
          display: 'flex',
          border: '1px solid #CCC',
          borderRadius: '4px',
          flex: 1,
          alignItems: 'center'
        }}>
          <div style={{
            height: '100%',
            minWidth: '45%',
            background: '#EEE',
            backgroundImage: 'url(https://www.sephora.fr/on/demandware.static/-/Library-Sites-SephoraV2/default/dwe84e9f18/SN-7790-FR-UNDERBANNER.jpg)',
            backgroundSize: 'cover'
          }}></div>
          <div style={{padding: '24px'}}>
          <h3 style={{fontSize: '14px', marginTop: 0, marginBottom: '6px'}}>Les Offres Spéciales du moment</h3>
          <p style={{fontSize: '14px', marginTop: 0, marginBottom: '12px'}}>Les marques vous gâtent avec des cadeaux offerts lors de votre commande selon le contenu de votre panier !</p>
          <Button type={ButtonTypes.Tertiary} text="Je veux un cadeau"/>
          </div>
        </div>
      </div>*/}
      <Query
        query={HOMEPAGE_PRODUCTS_QUERY}
        variables={{ categoryId: "64", limit: 10 }}
      >
        {({ category }) => {
          return (
            <HeadbandCarousel
              showButton={true}
              itemCarousel={category.products.items}
              title={"Nouveautés"}
              urlPath={category.urlPath}
              textButton={"Je découvre"}
              description={
                "Découvrez les dernières nouveautés qui ont fait leur rentrée !"
              }
            />
          );
        }}
      </Query>
      <Query
        query={HOMEPAGE_PRODUCTS_QUERY}
        variables={{ categoryId: "4", limit: 10 }}
      >
        {({ category }) => {
          return (
            <HeadbandCarousel
              showButton={true}
              itemCarousel={category.products.items}
              title={category.name}
              urlPath={category.urlPath}
              textButton={"Voir tous les parfums"}
              description={category.description}
            />
          );
        }}
      </Query>
      <Query
        query={HOMEPAGE_PRODUCTS_QUERY}
        variables={{ categoryId: "7", limit: 10 }}
      >
        {({ category }) => {
          return (
            <HeadbandCarousel
              showButton={true}
              itemCarousel={category.products.items}
              title={category.name}
              urlPath={category.urlPath}
              textButton={"Découvrir les soins"}
              description={category.description}
              imgLazyLoading={true}
            />
          );
        }}
      </Query>
      <Query
        query={HOMEPAGE_PRODUCTS_QUERY}
        variables={{ categoryId: "5", limit: 10 }}
      >
        {({ category }) => {
          return (
            <HeadbandCarousel
              showButton={true}
              itemCarousel={category.products.items}
              title={category.name}
              urlPath={category.urlPath}
              textButton={"Voir tout le maquillage"}
              description={category.description}
              imgLazyLoading={true}
            />
          );
        }}
      </Query>
      <Query
        query={HOMEPAGE_PRODUCTS_QUERY}
        variables={{ categoryId: "9", limit: 10 }}
      >
        {({ category }) => {
          return (
            <HeadbandCarousel
              showButton={true}
              itemCarousel={category.products.items}
              title={category.name}
              urlPath={category.urlPath}
              textButton={"Découvrir la parapharmacie"}
              description={category.description}
              imgLazyLoading={true}
            />
          );
        }}
      </Query>
      <>
        <Headband
          showButton
          title={"Les marques trending"}
          textButton={"Voir toutes les marques"}
          urlPath={"/marques"}
          description="Pour connaître les nouveautés de vos marques favorites avant tout le monde,
                    suivez les marques que vous préférez.
                    Débloquez ainsi une expérience personnalisée sur le site BENLUX."
        />
        <CardBrand brand={brand} />
      </>
      <Headband
        title={"#BENLUX"}
        description={
          "Vous avez besoin d'une dose d'inspiration quotidienne ? Retrouvez chaque jour nos conseils, idées cadeaux et présentations produits directement sur notre compte Instagram !"
        }
        urlPath={"https://www.instagram.com/benluxparis"}
        externalLink
        showButton
        textButton={"Retrouvez-nous sur Instagram"}
      />
      <InstagramPostsQuery>
        {({ instagramPosts }) => {
          return <InstagramPosts posts={instagramPosts.posts} />;
        }}
      </InstagramPostsQuery>
      <CMSBlockQuery variables={{ identifier: "homepage-store-description" }}>
        {({ cmsBlock, loading }) => {
          if (!cmsBlock && loading) {
            return <Loader />;
          }
          const data = JSON.parse(cmsBlock.content);
          return <StoreDescription content={data} />;
        }}
      </CMSBlockQuery>
    </HomeBox>
  </div>
);

export default Home;
