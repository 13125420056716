import lamer from '../assets/lamer.png'
import gucci from '../assets/gucci.png'
import chloe from '../assets/chloe.png'
import yvessaintlaurent from '../assets/yvessaintlaurent.png'
import lancome from '../assets/lancome.png'
import clarins from '../assets/clarins.png'
import jeanPaulGaultier from '../assets/jean-paul-gaultier.png';

export const brand = [
  {
    id: '3',
    title: 'La Mer',
    urlPath: '/la-mer',
    urlImg: lamer,
    cssClass: '',
    __typename: 'MenuItem'
  },
  {
    id: '4',
    title: 'Jean Paul Gaultier',
    urlPath: '/jean-paul-gaultier',
    urlImg: jeanPaulGaultier,
    cssClass: '',
    __typename: 'MenuItem'
  },
  {
    id: '4',
    title: 'Gucci',
    urlPath: '/gucci',
    urlImg: gucci,
    cssClass: '',
    __typename: 'MenuItem'
  },
  {
    id: '4',
    title: 'Chloé',
    urlPath: '/chloe',
    urlImg: chloe,
    cssClass: '',
    __typename: 'MenuItem'
  },
  {
    id: '',
    title: 'Clarins',
    urlPath: '/clarins',
    urlImg: clarins,
    cssClass: '',
    __typename: 'MenuItem'
  },
  {
    id: '',
    title: 'Yves Saint-Laurent',
    urlPath: '/yves-saint-laurent',
    urlImg: yvessaintlaurent,
    cssClass: '',
    __typename: 'MenuItem'
  },
  {
    id: '',
    title: 'Lancôme',
    urlPath: '/lancome',
    urlImg: lancome,
    cssClass: '',
    __typename: 'MenuItem'
  }
];
