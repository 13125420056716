import gql from "graphql-tag";
import { Query } from "@deity/falcon-ecommerce-uikit";

export const GET_HOMEPAGE_SLIDES = gql`
  query HomepageSlides {
    homepageSlides {
      id
      alt
      path
      desktopImg
      mobileImg
      rank
    }
  }
`;

export class HomepageSlidesQuery extends Query {
  static defaultProps = {
    query: GET_HOMEPAGE_SLIDES
  };
}
